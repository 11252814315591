/**
 * Custom js
 * requires jquery
 */

window.APP = {};

/**
 * INIT STUFF
 */

APP.token = document.querySelector('meta[name="csrf-token"]').content;

APP.init = function() {

	// textarea auto height
    $('textarea:visible').each(function() {
		$(this).css({
            'height': this.scrollHeight,
            'min-height': (this.rows || 2) + 'rem',
            'overflow-y': 'hidden'
        });
    }).on('input change keyup', function () {
        $(this).css('height', 'auto');
        $(this).css({
            'height': this.scrollHeight
        });
	});



	// Trumbowyg editor auto-init
	// $('[data-editor]').trumbowyg({
	// 	svgPath: '/dist/icons.svg',
	// 	autogrow: true,
	// 	removeformatPasted: true,
	// 	btns: [
	// 		'strong', 'em', 'underline',
	// 		'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull',
	// 		'unorderedList', 'orderedList'
	// 	]
	// });

};



/**
 * MODALS
 */

APP.modalTimer = null;

APP.closeModals = function(callback) {
	$('body').removeAttr('style'); // Hack for når man åpner ny modal før denne er helt fjernet
	$('.modal').removeClass('fade').modal('hide').on('hidden.bs.modal',function(event) {
		if (typeof callback == 'function') {
			callback(event);
		}
	});
}

APP.modal = function(source, openCallback, closeCallback) {
	APP.closeModals();
	clearTimeout(APP.modalTimer);

	let createModal = function($modal) {
		$modal.modal()
			.on('shown.bs.modal',function(event) {
				$(this).find('[data-modal-focus]').focus();
				APP.init();
			}).on('hidden.bs.modal',function(event) {
				$(this).remove();
				if (typeof closeCallback == 'function') {
					closeCallback(response);
				}
			}).appendTo('body');
		if (typeof openCallback == 'function') {
			openCallback(response);
		}
	}

	APP.modalTimer = setTimeout(function(){
		if (source.indexOf("/") > -1) {

			// Lag ny modal fra url
			$.ajax({
				url: source,
				dataType: 'html',
				success: function(response){
					createModal($(response).filter('.modal'));
				}
			});

		} else if ($(source).length) {

			// Lag ny modal med innhold fra div
			let $content = $(source);
			let size = $content.data('modal-size') || '';

			let $cover = $('<div class="modal fade" id="'+$content.attr('id')+'_modal">');
			let $window = $('<div class="modal-dialog modal-'+size+'">');
			let $closebtn = $('<button type="button" class="close" data-dismiss="modal"><i class="fa fa-times"></i></a>');
			let $title = $('<h4 class="modal-title" style="line-height:1">').text($content.attr('name'))
			let $header = $('<div class="modal-header">').append($closebtn).append($title);
			let $body = $('<div class="modal-body">').html($content.html());
			let $inner = $('<div class="modal-content">').append($header).append($body);

			$window.append($inner);
			$cover.append($window);

			createModal($cover);

		} else {
			return false;
		}
	},10);
};

/**
 * ONLOAD
 */

$(function() {

	$('.breadcrumbs a').each(function() {
		$(this).after(' <i class="fa fa-angle-right text-muted mx-2" />');
	});

	APP.init();

});/* */
